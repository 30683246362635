import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2022.12.06',
    text: 'CAMPFIRE ENjiNEがエイベックス・クリエイター・エージェンシーと業務提携し、エンタテインメント業界におけるクラウドファンディングの活用を加速',
    url: 'https://prtimes.jp/main/html/rd/p/000000139.000016318.html'
  },
  {
    date: '2022.06.02',
    text: 'クラウドファンディングサイト「CAMPFIRE ENjiNE」がオープン！CAMPFIREとENjiNEネットワークの連携開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000116.000016318.html'
  },
  {
    date: '2022.01.04',
    text: 'RelicホールディングスとCAMPFIREが合弁会社「株式会社CAMPFIRE ENjiNE」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000095.000016318.html'
  }
  // {date: "2099.09.28", text: "テスト", url: "https://prtimes.jp/main/html/rd/p/000000085.000016318.html"}
]
